import { connect } from 'react-redux';

import { TeamForm } from '../../Components/TeamForm';
import { getChildrenCompanies, clearCompanyData } from '../../reducers/company';
import { getSites, clearSiteData } from '../../reducers/site';
import { getMembers } from '../../reducers/member';
import { getRelationalTeams } from '../../reducers/team';
import WithDefaults from '../WithDefaults';

export const mapStateToProps = (state, ownProps) => ({
    members: state.member && state.member.list,
    relations: state.team && state.team.relations,
    crmServiceEnabled: state.appConfig && state.appConfig.crmServiceEnabled,
    childrenCompanies: state.company && state.company.childrenCompany,
    sites: state.site && state.site.list,
    contractorCompanies: state.company && state.company.contractorCompany,
    auth: state && state.auth,
    appConfig: state.appConfig,
    ...ownProps
  });

export const mapDispatchToProps = (dispatch) => ({
  getChildrenCompanies(...args) {
    return getChildrenCompanies(dispatch, ...args);
  },
  getSites(...args) {
    return getSites(dispatch, ...args);
  },
  clearCompanyData(...args) {
    return clearCompanyData(dispatch, ...args);
  },
  clearSiteData(...args) {
    return clearSiteData(dispatch, ...args);
  },
  getMembers(...args) {
    return getMembers(dispatch, ...args);
  },
  getRelationalTeams(...args) {
    return getRelationalTeams(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(TeamForm));
